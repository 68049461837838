import { createReducer } from "../app/common/utils/reducerUtils";
import { RESET_ACCOUNT_PASSWORD, RESET_PASSWORD_RESET_DATA } from "./authConstant";


const initialState = {
    errorMessages: null,
}

const resetAccountPassword = (state, payload) => {
    return {
        ...state,
        errorMessages: payload.errorMessages
    }
}
const resetPasswordResetData = (state, payload) => {
    return {
        ...state,
        errorMessages: null
    }
}


export default createReducer(initialState, {
    [RESET_ACCOUNT_PASSWORD]: resetAccountPassword,
    [RESET_PASSWORD_RESET_DATA]: resetPasswordResetData
});
