import { LOAD_DATA_DELETION_REQUEST, RESET_GDPR_DATA } from "./GDPRConstants";


export const loadDataDeletionRequest = (confirmationCode) => {

    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();
        try {

            let facebookDataDeletionRequestDocSnap = await db.doc(`facebook_data_deletion_requests/${confirmationCode}`).get();

            dispatch({ type: LOAD_DATA_DELETION_REQUEST, payload: { isFound: facebookDataDeletionRequestDocSnap.exists } })

        } catch (err) {
            console.log(err)
        }
    }
}

export const resetGDPRData = () => {

    return async dispatch => {
        try {
            dispatch({ type: RESET_GDPR_DATA });
        } catch (err) {
            console.log(err);
        }
    };
}