import { Col, Modal, Row, Typography } from "antd";
import React, { Fragment } from "react";
const { Text } = Typography;

const PasswordResetFailedModal = ({
  isVisible,
  onCancel,
  onOk,
  errorMessages,
}) => {
  return (
    <Fragment>
      <Modal
        title={<Text style={{ fontSize: "16px" }}>Password Reset Failed</Text>}
        visible={isVisible}
        onCancel={onCancel}
        onOk={onOk}
      >
        <Row>
          <Col span={24}>
            {errorMessages && (
              <Text
                style={{
                  fontSize: "14px",
                }}
              >
                {errorMessages}
              </Text>
            )}
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default PasswordResetFailedModal;
