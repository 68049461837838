import { subdomainTypeEnum } from "../SubdomainTypeConstant";
import { VALIDATE_SUBDOMAIN } from "./domainConstant";

export const validateSubdomain = () => {
    return async (dispatch, getState) => {
        try {

            let hostName = window.location.hostname;
            let sharedDomain = getState().domain.sharedDomain;
            let regExp = new RegExp(sharedDomain, "g");


            let subdomainType = subdomainTypeEnum.UNDEFINED; //set default

            if (hostName.match(regExp) !== null) {
                let subDomainRegexp = new RegExp(`(.*).${sharedDomain}`, "g");
                let regexpResult = subDomainRegexp.exec(hostName);

                if (regexpResult !== null) {
                    if (regexpResult[1] === "gdpr") {
                        subdomainType = subdomainTypeEnum.GDPR;
                    } else if (regexpResult[1] === "password-reset") {
                        subdomainType = subdomainTypeEnum.PASSWORD_RESET;
                    } else if (regexpResult[1] === "email-verified") {
                        subdomainType = subdomainTypeEnum.EMAIL_VERIFIED;
                    }

                }
            }
            dispatch({ type: VALIDATE_SUBDOMAIN, payload: { subdomainType } });


        } catch (err) {
            console.log(err)
        }
    }
}