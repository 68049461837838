import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { loadDataDeletionRequest, resetGDPRData } from "./GDPR/GDPRActions";
import { Fragment } from "react";
import { Col, Row } from "antd";

class GDPRHomePage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { match } = this.props;

    this.props.loadDataDeletionRequest(match.params.confirmationCode);
  }

  componentWillUnmount() {
    this.props.resetGDPRData();
  }

  componentDidUpdate(prevProps) {
    const { gdpr } = this.props;
    if (
      !_.isEqual(prevProps.gdpr.isFound, gdpr.isFound) &&
      gdpr.isFound !== null
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { isLoading } = this.state;
    const { match, gdpr } = this.props;
    if (isLoading) return null;

    return (
      <Fragment>
        {gdpr.isFound ? (
          <Row>
            <Col
              offset={6}
              span={12}
              style={{
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Your data deletion request has been successfully processed. Your
              confirmation code for this request is{" "}
              {match.params.confirmationCode}.
              <br />
              <br />
              Let us know if you have further queries, you can contact us at
              support@123KidsAcademy.com
            </Col>
            <Col span={6} />
          </Row>
        ) : (
          <Row>
            <Col
              offset={6}
              span={12}
              style={{
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Sorry. Invalid confirmation code.
              <br />
              <br />
              Let us know if you have further queries, you can contact us at
              support@123KidsAcademy.com
            </Col>
            <Col span={6} />
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  gdpr: state.gdpr,
});

const mapDispatchToProps = {
  loadDataDeletionRequest,
  resetGDPRData,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GDPRHomePage
);
