import { firebaseFunc } from '../app/config/firebase';
import { RESET_ACCOUNT_PASSWORD, RESET_PASSWORD_RESET_DATA } from './authConstant';
import { operationTypeEnum } from "../OperationTypeConstant";
import { operationStarted, operationFailed, operationCompleted } from "../operation/operationActions";

export const resetAccountPassword = (resetToken, newPassword) => {
    return async (dispatch, getState) => {
        try {
            dispatch(operationStarted(operationTypeEnum.RESET_ACCOUNT_PASSWORD))

            let resetAccountPassword = firebaseFunc.httpsCallable("resetAccountPassword");
            let errorMessages = null;

            let result = await resetAccountPassword({ resetToken, newPassword });

            if (result.data.code === 200) {
                //ignore intentionally
            } else {
                if (result.data.error === "InvalidParams") {
                    if (result.data.errorDetails.Password) {
                        //TODO:
                        errorMessages = result.data.errorDetails.Password[0]; //hardcode here..
                    }
                } else if (result.data.error === "AuthTokenDoesNotExist") {
                    errorMessages = "Invalid token or token expired. Please request for password reset again."
                } else {
                    errorMessages = "Internal Error. Please contact support."
                }
            }
            dispatch({ type: RESET_ACCOUNT_PASSWORD, payload: { errorMessages } })

            dispatch(operationCompleted())

        } catch (err) {
            console.log(err)
            dispatch(operationFailed())
        }
    }
}

export const resetPasswordResetData = () => {
    return async (dispatch) => {
        try {

            dispatch({ type: RESET_PASSWORD_RESET_DATA })

        } catch (err) {
            console.log(err)
        }
    }
}