import { OPERATION_STARTED, OPERATION_FAILED, OPERATION_COMPLETED } from "./operationConstant"
import { createReducer } from "../app/common/utils/reducerUtils"
import { operationTypeEnum } from "../OperationTypeConstant"

const initialState = {
    isProcessing: false,
    operationType: operationTypeEnum.NO_OPS,
    internalError: ""
}

const operationStarted = (state, payload) => {
    return {
        ...state,
        isProcessing: true,
        operationType: payload.operationType
    }
}

const operationFailed = (state) => {
    return {
        ...state,
        isProcessing: false
    }
}

const operationCompleted = (state) => {
    return {
        ...state,
        isProcessing: false,
        operationType: operationTypeEnum.NO_OPS
    }
}

export default createReducer(initialState, {
    [OPERATION_STARTED]: operationStarted,
    [OPERATION_FAILED]: operationFailed,
    [OPERATION_COMPLETED]: operationCompleted,
})