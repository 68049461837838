import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { validateSubdomain } from "./domain/domainActions";

class DomainVal extends Component {
  state = { isLoading: true };

  async componentDidMount() {
    await this.props.validateSubdomain();
  }

  componentDidUpdate(prevProps) {
    const { domain } = this.props;
    if (
      !_.isEqual(prevProps.domain.subdomainType, domain.subdomainType) &&
      domain.subdomainType !== null
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return null;
    return children;
  }
}

const mapStateToProps = (state) => ({
  domain: state.domain,
});

const mapDispatchToProps = {
  validateSubdomain,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DomainVal);
