import { createReducer } from "../app/common/utils/reducerUtils";
import { LOAD_DATA_DELETION_REQUEST, RESET_GDPR_DATA } from "./GDPRConstants";


const initialState = {
    isFound: null
}

const loadDataDeletionRequest = (state, payload) => {
    return {
        ...state,
        isFound: payload.isFound
    }
}


const resetGDPRData = (state) => {
    return {
        ...state,
        isFound: null
    }
}


export default createReducer(initialState, {
    [LOAD_DATA_DELETION_REQUEST]: loadDataDeletionRequest,
    [RESET_GDPR_DATA]: resetGDPRData
});