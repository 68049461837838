import { VALIDATE_SUBDOMAIN } from "./domainConstant";
import { createReducer } from "../app/common/utils/reducerUtils";


const initialState = {
    sharedDomain: "123kidsacademy.com",
    subdomainType: null,
}

const validateSubDomain = (state, payload) => {
    return {
        ...state,
        subdomainType: payload.subdomainType
    }
}


export default createReducer(initialState, {
    [VALIDATE_SUBDOMAIN]: validateSubDomain,
});
