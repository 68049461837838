import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/functions';
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAHBXfMe75SX05hXZ5Lp_NYhrdZH-Kypvo",
  authDomain: "kids-academy-123.firebaseapp.com",
  databaseURL: "https://kids-academy-123.firebaseio.com",
  projectId: "kids-academy-123",
  storageBucket: "kids-academy-123.appspot.com",
  messagingSenderId: "321257511879",
  appId: "1:321257511879:web:9f14982a29241b55a697e2",
  measurementId: "G-YMJ99L64JN"
};


firebase.initializeApp(firebaseConfig);
firebase.firestore();

const firebaseFunc = firebase.app().functions();

export { firebase, firebaseFunc };
